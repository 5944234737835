import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import Logo from "../images/RTDS-branding-social.png"
import "./layout.css"
import styled from "styled-components"

const Header = ({ siteTitle }) => (
  <>
  <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/ueq2cmo.css" />
      </Helmet>
 
  <HeaderWrapper>
    <div>
      <ul>
        <li>
        <Link to="/races">Races</Link>
        </li>
        <li>
        <Link to="/routes">Routes</Link>
        </li>
        <li>
        <Link to="/"><img src={Logo} alt="logo" /></Link>
        </li>  
        <li>
        <Link to="/articles">Articles</Link>
        </li>
        <li>
        <Link to="/reviews">Reviews</Link>
        </li>
      </ul>
    </div>
  </HeaderWrapper>  
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const HeaderWrapper = styled.div`
  font-family: 'B612', sans-serif;
  padding-top: 1rem;
  display: grid;
  //grid-template-columns: 1fr 0.5fr;
  font-size: 1.2rem;
  min-height: 10vh;
  //font-family: 'Playfair Display', serif;
  background-color: #111;
  color: #818181;
  font-size: 2rem;
  


  img {
    max-width: 50%;
    height: auto;
  }

  ul {
    padding-top: 2.2rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    place-items: center center;
    list-style: none;
    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }
  li {
    text-align: center;
  }


  // margin: auto auto;
  // // maxWidth: 1200,
  // padding: 0rem 0rem;
  // display: grid;
  // grid-template-columns: 2fr 2fr;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    justify-items: center;
    ul {
      padding-left: 0;
      li {
        margin-bottom: 10px;
      }
    }
    img {
      max-width: 25%;
      height: auto;
    }
  }
`

const HeaderItem = styled.div`
  padding: 0rem 0rem;
  color: #FFF8F0;

`

export default Header
