import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { SocialIcon } from "react-social-icons"

import styled from "styled-components"
import "./layout.css"

const Footer = () => (
    <FooterWrapper>
      <FooterCol>
      <Link to="/"><h2>Home</h2></Link>
        <br />
        <Link to="/races">Races</Link>
        <br />
        <Link to="/routes">Routes</Link>
        <br />
        <Link to="/articles">Articles</Link>
        <br />
        <Link to="/reviews">Reviews</Link>
        <br />
        © Running the Dirty South 2020
        Made by Ryan James
      </FooterCol>
      <FooterCol>
        <div></div>
      </FooterCol>
      <FooterCol style={{
      alignSelf: `flex-end`,
    }}>
      <ul>
          <li><SocialIcon url="mailto: runningthedirtysouth@gmail.com"/></li>
          <li><SocialIcon url="https://www.instagram.com/runningthedirtysouth"/></li>
        </ul> 
      </FooterCol>
    </FooterWrapper>
)

// Styled components:
const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 0.5fr;
  background: #2eff9f0;
  // color: #00120b;
  font-size: 1.2rem;
  min-height: 40vh;
  padding-top: 2rem;
  
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    margin-left: 20px;
    margin-right: 20px;
    
    .display-1 {
      font-size: 2.4rem;
      text-align: left;
    }
  }

  `

  const FooterCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 50px;
  padding-right: 50px;
  font-family: 'Source Sans Pro', sans-serif;
  color: gray;

  h2 {
    font-family: 'Playfair Display', serif;
  }
  
  ul {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    font-size: 16px;
    list-style: none;
    li {
      margin-bottom: 10px;
    }
  }
    `



export default Footer